<template>
    <div class="about" id="card">
        <span class="badge">A Recreational Math Joint</span>
    </div>
    <div class="dot-grid">
        <div id="item-0" class="item">
            <div class="dot dot-0" v-bind:style="{ backgroundColor: color1 }">
                <input type="color" id="one" v-model="color1" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-1" class="item">
            <div class="dot dot-1" v-bind:style="{ backgroundColor: color2 }">
                <input type="color" id="two" v-model="color2" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-2" class="item">
            <div class="dot dot-2" v-bind:style="{ backgroundColor: color3 }">
                <input type="color" id="three" v-model="color3" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-3" class="item">
            <div class="dot dot-3" v-bind:style="{ backgroundColor: color4 }">
                <input type="color" id="four" v-model="color4" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-4" class="item">
            <div class="dot dot-4" v-bind:style="{ backgroundColor: color5 }">
                <input type="color" id="five" v-model="color5" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-5" class="item">
            <div class="dot dot-5" v-bind:style="{ backgroundColor: color6 }">
                <input type="color" id="six" v-model="color6" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-6" class="item">
            <div class="dot dot-6" v-bind:style="{ backgroundColor: color7 }">
                <input type="color" id="seven" v-model="color7" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-7" class="item">
            <div class="dot dot-7" v-bind:style="{ backgroundColor: color8 }">
                <input type="color" id="eight" v-model="color8" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-8" class="item">
            <div class="dot dot-8" v-bind:style="{ backgroundColor: color9 }">
                <input type="color" id="nine" v-model="color9" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-9" class="item">
            <div class="dot dot-9" v-bind:style="{ backgroundColor: color10 }">
                <input type="color" id="ten" v-model="color10" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-10" class="item">
            <div class="dot dot-10" v-bind:style="{ backgroundColor: color11 }">
                <input type="color" id="eleven" v-model="color11" v-on:change="handleColorChange">
            </div>
        </div>
        <div id="item-11" class="item">
            <div class="dot dot-11" v-bind:style="{ backgroundColor: color12 }">
                <input type="color" id="twelve" v-model="color12" v-on:change="handleColorChange">
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getFirestore, query, collection, where, doc, getDoc, getDocs, onSnapshot, updateDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD6Z-z71u1sZNxLvQXsA9j7NCQK50rxHIM",
    authDomain: "duodecimal-ac2cf.firebaseapp.com",
    databaseURL: "https://duodecimal-ac2cf-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "duodecimal-ac2cf",
    storageBucket: "duodecimal-ac2cf.appspot.com",
    messagingSenderId: "1010762609782",
    appId: "1:1010762609782:web:9baf83240dfd88ccf79b57",
    measurementId: "G-TNGEGC4L0Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default {
    name: 'DotsView',
    data() {
        return {
            color1: "",
            color2: "",
            color3: "",
            color4: "",
            color5: "",
            color6: "",
            color7: "",
            color8: "",
            color9: "",
            color10: "",
            color11: "",
            color12: ""
        }
    },
    props: {
        msg: String
    },
    created() {
        this.getColor()

        const q = query(collection(db, "dots"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const dots = [];
            querySnapshot.forEach((doc) => {
                dots.push(doc.data().color);
                this.setColor(doc.data().number, doc.data().color);
            });
            console.log("Current dots: ", dots.join(", "));
        });
    },
    methods: {
        async getColor() {
            const q = query(collection(db, "dots"));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                this.setColor(doc.data().number, doc.data().color);
            });
        },

        async handleColorChange(event) {
            var colorId = this.getColorId(event.target.id)
            var docId = ""
            const q = query(collection(db, "dots"), where("number", "==", colorId));
            console.log("Query: dot number == " + colorId)
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => { // Should only ever be 1 
                console.log(doc.id, " => ", doc.data());
                docId = doc.id;
            });
            this.updateDot(docId, event.target.value)

            // TODO: Write a new record to firstore snapshot history
        },
        setColor(id, colorValue) {
            console.log("setColor: " + id + " To: " + colorValue)
            switch (id) {
                case 1:
                    console.log("Setting color1 to: " + colorValue)
                    this.color1 = colorValue;
                    break;
                case 2:
                    this.color2 = colorValue;
                    break;
                case 3:
                    this.color3 = colorValue;
                    break;
                case 4:
                    this.color4 = colorValue;
                    break;
                case 5:
                    this.color5 = colorValue;
                    break;
                case 6:
                    this.color6 = colorValue;
                    break;
                case 7:
                    this.color7 = colorValue;
                    break;
                case 8:
                    this.color8 = colorValue;
                    break;
                case 9:
                    this.color9 = colorValue;
                    break;
                case 10:
                    this.color10 = colorValue;
                    break;
                case 11:
                    this.color11 = colorValue;
                    break;
                case 12:
                    this.color12 = colorValue;
                    break;
                default:
                    break;
            }
        },
        getColorId(id) {
            var result = 0
            switch (id) {
                case "one":
                    result = 1
                    break;
                case "two":
                    result = 2
                    break;
                case "three":
                    result = 3
                    break;
                case "four":
                    result = 4
                    break;
                case "five":
                    result = 5
                    break;
                case "six":
                    result = 6
                    break;
                case "seven":
                    result = 7
                    break;
                case "eight":
                    result = 8
                    break;
                case "nine":
                    result = 9
                    break;
                case "ten":
                    result = 10
                    break;
                case "eleven":
                    result = 11
                    break;
                case "twelve":
                    result = 12
                    break;
                default:
                    break;
            }
            return result
        },
        async updateDot(docId, colorValue) {
            console.log("DocId to Update: " + docId)
            const docRef = doc(db, "dots", docId)
            await updateDoc(docRef, {
                color: colorValue
            })
        }
    }
}
</script>

<style>
.about {
    position: absolute;
    bottom: 16px;
    right: 16px;
    font-size: 12px;
    display: none;
}

.badge {
    background-color: peru;
    color: white;
    padding: 4px 8px;
    text-align: center;
    border-radius: 5px;
}

.dot-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0px;
    height: 100%;
}

.dot {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .6s all cubic-bezier(0.74, -0.46, 0, 1.13);
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
}

#item-0 {
    /* background-color: #adf95b;  */
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 2;
}

#item-1 {
    grid-row-start: 1;
    grid-column-start: 2;

    grid-row-end: 2;
    grid-column-end: 3;
}

#item-2 {
    grid-row-start: 1;
    grid-column-start: 3;

    grid-row-end: 2;
    grid-column-end: 4;
}

#item-3 {
    grid-row-start: 1;
    grid-column-start: 4;

    grid-row-end: 2;
    grid-column-end: 5;
}

#item-4 {
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 3;
    grid-column-end: 2;
}

#item-5 {
    grid-row-start: 2;
    grid-column-start: 2;

    grid-row-end: 3;
    grid-column-end: 3;
}

#item-6 {
    grid-row-start: 2;
    grid-column-start: 3;

    grid-row-end: 3;
    grid-column-end: 4;
}

#item-7 {
    grid-row-start: 2;
    grid-column-start: 4;

    grid-row-end: 3;
    grid-column-end: 5;
}

#item-8 {
    grid-row-start: 3;
    grid-column-start: 1;

    grid-row-end: 4;
    grid-column-end: 2;
}

#item-9 {
    grid-row-start: 3;
    grid-column-start: 2;

    grid-row-end: 4;
    grid-column-end: 3;
}

#item-10 {
    grid-row-start: 3;
    grid-column-start: 3;

    grid-row-end: 4;
    grid-column-end: 4;
}

#item-11 {
    grid-row-start: 3;
    grid-column-start: 4;

    grid-row-end: 4;
    grid-column-end: 5;
}

input {
    height: 200px;
    width: 200px;
    opacity: 0;
    cursor: pointer;
}

@media all and (min-width: 960px) {
    .dot {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .6s all cubic-bezier(0.74, -0.46, 0, 1.13);
    }

    .about {
        display: block;
    }
}
</style>