import { createApp } from 'vue'
import App from './App.vue'
/* eslint-disable no-unused-vars */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD6Z-z71u1sZNxLvQXsA9j7NCQK50rxHIM",
  authDomain: "duodecimal-ac2cf.firebaseapp.com",
  databaseURL: "https://duodecimal-ac2cf-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "duodecimal-ac2cf",
  storageBucket: "duodecimal-ac2cf.appspot.com",
  messagingSenderId: "1010762609782",
  appId: "1:1010762609782:web:9baf83240dfd88ccf79b57",
  measurementId: "G-TNGEGC4L0Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// console.log(app.apiKey); 
// console.log(Firestore); 

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
// console.log(db.toJSON());

createApp(App).mount('#app')

// import { collection, getDocs } from "firebase/firestore"; 

// (async() => {
//   console.log("In the async function");
//   const querySnapshot = await getDocs(collection(db, "dots"));
//   console.log("Got the collection of docs");
//   querySnapshot.forEach((doc) => {
//     console.log(doc.data().color);
//   });
// })();