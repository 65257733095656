<template>
  <DotsView msg="DuoDecimal" />
</template>

<script>
import DotsView from './components/Dots.vue'

export default {
  name: 'App',
  components: {
    DotsView,
  }
}
</script>
<style>

html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
    background-color: #F6F7F2;
}

#app {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 640px;
  margin: auto;
}

.dot-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0;
    height:100%
}

.dot {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    transition:all .6s cubic-bezier(.74, -.46, 0, 1.13)
}

.dot, .item {
    display: flex;
    justify-content: center;
    align-items:center
}

#item-0 {
    grid-column-start: 1;
    grid-column-end:2
}

#item-0, #item-1 {
    grid-row-start: 1;
    grid-row-end:2
}

#item-1 {
    grid-column-start: 2;
    grid-column-end:3
}

#item-2 {
    grid-column-start: 3;
    grid-column-end:4
}

#item-2, #item-3 {
    grid-row-start: 1;
    grid-row-end:2
}

#item-3 {
    grid-column-start: 4;
    grid-column-end:5
}

#item-4 {
    grid-column-start: 1;
    grid-column-end:2
}

#item-4, #item-5 {
    grid-row-start: 2;
    grid-row-end:3
}

#item-5 {
    grid-column-start: 2;
    grid-column-end:3
}

#item-6 {
    grid-column-start: 3;
    grid-column-end:4
}

#item-6, #item-7 {
    grid-row-start: 2;
    grid-row-end:3
}

#item-7 {
    grid-column-start: 4;
    grid-column-end:5
}

#item-8 {
    grid-column-start: 1;
    grid-column-end:2
}

#item-8, #item-9 {
    grid-row-start: 3;
    grid-row-end:4
}

#item-9 {
    grid-column-start: 2;
    grid-column-end:3
}

#item-10 {
    grid-column-start: 3;
    grid-column-end:4
}

#item-10, #item-11 {
    grid-row-start: 3;
    grid-row-end:4
}

#item-11 {
    grid-column-start: 4;
    grid-column-end:5
}

input {
    height: 200px;
    width: 200px;
    opacity: 0;
    cursor:pointer
}

@media (min-width: 960px) {
    .dot {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:all .6s cubic-bezier(.74, -.46, 0, 1.13)
    }
}


@media (max-width: 2660px) {
    .dot-grid {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0;
        height:100%
    }

    #item-0 {
        grid-column-start: 1;
        grid-column-end:2
    }

    #item-0, #item-1 {
        grid-row-start: 1;
        grid-row-end:2
    }

    #item-1 {
        grid-column-start: 2;
        grid-column-end:3
    }

    #item-2 {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 2;
        grid-column-end:4
    }

    #item-3 {
        grid-column-start: 1;
        grid-column-end:2
    }

    #item-3, #item-4 {
        grid-row-start: 2;
        grid-row-end:3
    }

    #item-4 {
        grid-column-start: 2;
        grid-column-end:3
    }

    #item-5 {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end:4
    }

    #item-6 {
        grid-column-start: 1;
        grid-column-end:2
    }

    #item-6, #item-7 {
        grid-row-start: 3;
        grid-row-end:4
    }

    #item-7 {
        grid-column-start: 2;
        grid-column-end:3
    }

    #item-8 {
        grid-row-start: 3;
        grid-column-start: 3;
        grid-row-end: 4;
        grid-column-end:4
    }

    #item-9 {
        grid-column-start: 1;
        grid-column-end:2
    }

    #item-10, #item-9 {
        grid-row-start: 4;
        grid-row-end:5
    }

    #item-10 {
        grid-column-start: 2;
        grid-column-end:3
    }

    #item-11 {
        grid-row-start: 4;
        grid-column-start: 3;
        grid-row-end: 5;
        grid-column-end:4
    }
}

body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color:#f6f7f2
}

#app {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center
}

</style>


